import * as React from "react";
import {Link as RouterLink, useParams} from "react-router-dom";
import {Box, Container, Link, Typography, Button, ThemeProvider} from "@mui/material";
import {styled} from "@mui/system";
import {CATEGORIES} from "../../domain/helpers/categoriesHelpers";
import {useTheme} from "@mui/material/styles";
import {X} from "react-feather";
import {uiHelper} from "../../domain/helpers/uiHelper";

export const Nav = styled("nav")(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
        width: 350,
        height: "calc(100% - 89px)"
    },
    [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(0),
        paddingTop: 89
    }
}));

/**
 * La barre de navigation pour les pages catégories
 * @constructor
 */
export function CategoriesNav({onClick, isMobile}: {onClick?: () => void, isMobile: boolean}) {
    const {categoryId} = useParams();
    const theme = useTheme();

    return (
        <Nav>
            <Container maxWidth={"lg"} disableGutters={isMobile}>
                {isMobile && (
                    <Box 
                        display={"flex"}
                        alignItems={"center"}
                        sx={(theme) => ({
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            height: theme.spacing(11),
                        })}
                    >
                        <Button
                            disableRipple={true}
                            variant={"text"}
                            onClick={onClick}
                            sx={{
                                border: 0,
                                "&:hover": {
                                    background: "none",
                                }
                            }}
                        >
                            <X color={theme.palette.text.primary}/>
                        </Button>
                    </Box>
                )}
                <Box 
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={!isMobile ? "center" : "flex-start"}
                    flexDirection={!isMobile ? "row" : "column"}
                    height={!isMobile ? 94 : "auto"}
                    p={!isMobile ? 0 : 4}
                    sx={{borderBottom: `1px solid ${theme.palette.divider}`}}
                >
                    {Object.entries(CATEGORIES).map(([key, category]) => (
                        <ThemeProvider theme={category.theme} key={key}>
                            <Link
                                component={RouterLink}
                                to={"/categorie/" + category.slug}
                                onClick={onClick}
                                variant={"body2"}
                                underline={"none"}
                                className={`${(undefined === categoryId || uiHelper.getCategoryKeyFromCategoryId(categoryId) === key) ? "colored" : ""} ${uiHelper.getCategoryKeyFromCategoryId(categoryId) === key ? "active" :  ""}`}
                                sx={(theme) => ({
                                    display: "flex",
                                    alignItems: "flex-start",
                                    position: "relative",
                                    minHeight: 32,
                                    "&:not(:last-child)": {
                                        mr: {xs: 0, md : 8},
                                        mb: {xs: 4, md : 0},
                                    },
                                    "&:before": {
                                        content: "''",
                                        width: theme.spacing(2),
                                        height: 2,
                                        display: "block",
                                        position: "absolute",
                                        bottom: 0,
                                        left: theme.spacing(4),
                                        transition: "width .3s",
                                        backgroundColor: theme.palette.grey[200]
                                    },
                                    "&.colored:before": {
                                        backgroundColor: theme.palette.primary.main
                                    },
                                    "&:hover:before, &.active:before": {
                                        width: `calc(100% - ${theme.spacing(4)})`
                                    }
                                })}
                            >
                                <Box 
                                    display={"flex"}
                                    alignItems={"center"}
                                    mr={1}
                                    width={24}
                                    height={24}
                                    sx={(theme) => ({
                                        color: (undefined === categoryId || uiHelper.getCategoryKeyFromCategoryId(categoryId) === key) ? theme.palette.primary.main : theme.palette.grey[200]
                                    })}
                                >
                                    {category.icon}
                                </Box>
                                <Typography color={theme.palette.text.primary} variant={"body2"}>
                                    {category.label}
                                </Typography>
                            </Link>
                        </ThemeProvider>
                    ))}
                </Box>
                {isMobile && (
                    <Box p={2} display={"flex"} flexDirection={"column"}>
                        <Link href={"https://2fd37bfd.sibforms.com/serve/MUIEAIiP0wJsOWNuIuT-KqLwbiImgT4Ae2QskAPvqXXkrIYy0WuNGQGOZNr9r3tkMHjxqL9tPf-RApAAH98PuY7cm4MqnKP35tA4_vG_FJJS0fPW25uZksy1_k11qJwNq6BPoMxmEmLy7FWQLN5GR4b54-uow0nt2kBFxgvmbx5a9DH-Rbd2gGP7-982ztqoEFQNZp2XdSjhBVO2"} target={"_blank"} rel={"noreferrer noopener"} underline={"none"} component={"a"} mb={2}>
                            <Button variant={"contained"} color={"secondary"}>Newsletter</Button>
                        </Link>
                        <Link component={RouterLink} to={"/contact"} underline={"none"}>
                            <Button variant={"contained"} color={"primary"}>Contact & partenariat</Button>
                        </Link>
                    </Box>
                )}
            </Container>
        </Nav>
    );
}


